import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY
const redirectUrl = process.env.REACT_APP_REDIRECT_URL

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    redirectTo: redirectUrl
  }
})

const isTestEnvironment = process.env.REACT_APP_USE_TEST_DB === 'true'

export const getTable = () => {
  return isTestEnvironment ? 'Plans_testing' : 'plans'
}

export const getCompletedPlansTable = () => {
  return isTestEnvironment ? 'completed_plans_testing' : 'completed_plans'
}