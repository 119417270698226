/**
 * Utility function to check if a user has admin privileges
 * @param {string} userId - The user's ID to check
 * @returns {boolean} - Whether the user has admin privileges
 */
export const isAdmin = (userId) => {
  if (!userId) return false;

  // Check primary admin UUID
  const primaryAdminId = process.env.REACT_APP_ADMIN_UUID;
  if (primaryAdminId && userId === primaryAdminId) return true;

  // Check additional admin UUIDs
  const additionalAdminIds = process.env.REACT_APP_ADDITIONAL_ADMIN_UUIDS?.split(',') || [];
  return additionalAdminIds.includes(userId);
};