/**
 * Unified storage utility for handling localStorage operations
 */

// Constants for cache durations
const HOUR_IN_MS = 60 * 60 * 1000;
const DEFAULT_CACHE_DURATION = HOUR_IN_MS; // 1 hour

// Storage keys
const STORAGE_KEYS = {
  // Cache keys
  PLANS_CACHE: 'cachedPlans',
  PLANS_CACHE_TIMESTAMP: 'plansCacheTimestamp',
  APPAREL_CACHE: 'cachedApparel',
  APPAREL_CACHE_TIMESTAMP: 'apparelCacheTimestamp',
  
  // User progress keys
  COMPLETED_PLANS: 'completedPlans',
  COMPLETED_APPAREL: 'completedApparel',
  
  // Preferences
  THEME: 'theme',
};

/**
 * Base storage operations with error handling
 */
const baseStorage = {
  get(key) {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(`Error reading from localStorage [${key}]:`, error);
      return null;
    }
  },

  set(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (error) {
      console.error(`Error writing to localStorage [${key}]:`, error);
      return false;
    }
  },

  remove(key) {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      console.error(`Error removing from localStorage [${key}]:`, error);
      return false;
    }
  },
};

/**
 * Cache operations with timestamp management
 */
const cacheStorage = {
  get(key, timestampKey) {
    const data = baseStorage.get(key);
    const timestamp = baseStorage.get(timestampKey);
    
    if (!data || !timestamp) return null;
    
    const cacheAge = Date.now() - timestamp;
    return cacheAge < DEFAULT_CACHE_DURATION ? data : null;
  },

  set(key, timestampKey, value) {
    return (
      baseStorage.set(key, value) &&
      baseStorage.set(timestampKey, Date.now())
    );
  },

  clear(key, timestampKey) {
    baseStorage.remove(key);
    baseStorage.remove(timestampKey);
  },
};

/**
 * Specific storage operations for different data types
 */
export const storage = {
  // Plans operations
  plans: {
    getCache() {
      return cacheStorage.get(STORAGE_KEYS.PLANS_CACHE, STORAGE_KEYS.PLANS_CACHE_TIMESTAMP);
    },
    
    setCache(plans) {
      return cacheStorage.set(STORAGE_KEYS.PLANS_CACHE, STORAGE_KEYS.PLANS_CACHE_TIMESTAMP, plans);
    },
    
    getCompleted() {
      return baseStorage.get(STORAGE_KEYS.COMPLETED_PLANS) || {};
    },
    
    setCompleted(completed) {
      return baseStorage.set(STORAGE_KEYS.COMPLETED_PLANS, completed);
    },
  },

  // Apparel operations
  apparel: {
    getCache() {
      return cacheStorage.get(STORAGE_KEYS.APPAREL_CACHE, STORAGE_KEYS.APPAREL_CACHE_TIMESTAMP);
    },
    
    setCache(apparel) {
      return cacheStorage.set(STORAGE_KEYS.APPAREL_CACHE, STORAGE_KEYS.APPAREL_CACHE_TIMESTAMP, apparel);
    },
    
    getCompleted() {
      return baseStorage.get(STORAGE_KEYS.COMPLETED_APPAREL) || {};
    },
    
    setCompleted(completed) {
      return baseStorage.set(STORAGE_KEYS.COMPLETED_APPAREL, completed);
    },
  },

  // Theme operations
  theme: {
    get() {
      return baseStorage.get(STORAGE_KEYS.THEME) || 'default';
    },
    
    set(theme) {
      return baseStorage.set(STORAGE_KEYS.THEME, theme);
    },
  },

  // Clear all storage
  clearAll() {
    Object.values(STORAGE_KEYS).forEach(key => baseStorage.remove(key));
  },
};

export default storage;