import React, { useState } from 'react';
import { useAuth } from './AuthProvider';
import ForgotPasswordForm from './ForgotPasswordForm';
import './Auth.css';

const Auth = () => {
  const {
    formData,
    handleChange,
    loading,
    error,
    signIn,
    signUp,
    signInWithGoogle
  } = useAuth();

  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleAuth = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
    
    // Try sign in first
    await signIn({ email, password });
    
    // If error includes 'Invalid login', try sign up
    if (error?.includes('Invalid login')) {
      await signUp({ email, password });
    }
  };

  if (showForgotPassword) {
    return (
      <div className="auth-container">
        <ForgotPasswordForm onCancel={() => setShowForgotPassword(false)} />
      </div>
    );
  }

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h2>Welcome</h2>
        <p>Sign in or create an account to continue</p>

        <form onSubmit={handleAuth}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="your@email.com"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="••••••••"
              required
              minLength={6}
            />
            <button
              type="button"
              className="forgot-password-link"
              onClick={() => setShowForgotPassword(true)}
            >
              Forgot password?
            </button>
          </div>

          <button 
            type="submit" 
            className="custom-button auth-button" 
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Continue'}
          </button>

          {error && (
            <div className="auth-message error" role="alert">
              {error}
            </div>
          )}
        </form>

        <div className="auth-divider">
          <span>or</span>
        </div>

        <button
          onClick={signInWithGoogle}
          className="google-button"
          type="button"
          disabled={loading}
        >
          Continue with Google
        </button>
      </div>
    </div>
  );
};

export default Auth;