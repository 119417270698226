import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { supabase } from '../supabaseClient';
import useFormInput from '../utils/hooks/useFormInput';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const AUTH_VALIDATION = {
  email: {
    type: 'text',
    validate: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
  },
  password: {
    type: 'text',
    minLength: 6
  }
};

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, handleChange, resetForm] = useFormInput(
    { email: '', password: '' },
    AUTH_VALIDATION
  );

  // Initialize session
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleError = useCallback((error) => {
    setError(error.message);
    setTimeout(() => setError(null), 5000);
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    try {
      setLoading(true);
      setError(null);
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  const signUp = useCallback(async ({ email, password }) => {
    try {
      setLoading(true);
      setError(null);
      const { error } = await supabase.auth.signUp({ email, password });
      if (error) throw error;
      return { success: true, message: 'Check your email for the confirmation link.' };
    } catch (error) {
      handleError(error);
      return { success: false, message: error.message };
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  const signInWithGoogle = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.origin
        }
      });
      if (error) throw error;
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  const resetPassword = useCallback(async (email) => {
    try {
      setLoading(true);
      setError(null);
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${process.env.REACT_APP_REDIRECT_URL}/#/change-password`,
      });
      if (error) throw error;
      return { success: true, message: 'Check your email (including spam folder) for reset instructions.' };
    } catch (error) {
      handleError(error);
      return { success: false, message: error.message };
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  const verifyResetToken = useCallback(async (token) => {
    try {
      setLoading(true);
      setError(null);
      const { error } = await supabase.auth.verifyOtp({
        token_hash: token,
        type: 'recovery'
      });
      if (error) throw error;
      return { success: true };
    } catch (error) {
      handleError(error);
      return { success: false, message: 'Invalid or expired reset token' };
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  const updatePassword = useCallback(async (newPassword) => {
    try {
      setLoading(true);
      setError(null);
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });
      if (error) throw error;
      return { success: true, message: 'Password updated successfully.' };
    } catch (error) {
      handleError(error);
      return { success: false, message: error.message };
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  const signOut = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      resetForm();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [handleError, resetForm]);

  const value = {
    session,
    loading,
    error,
    formData,
    handleChange,
    signIn,
    signUp,
    signInWithGoogle,
    signOut,
    resetPassword,
    verifyResetToken,
    updatePassword
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
