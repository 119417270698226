import React, { useState } from 'react';
import useFormInput from '../utils/hooks/useFormInput';
import './FeedbackForm.css';

const FEEDBACK_TYPES = [
  { value: 'bug', label: 'Bug Report' },
  { value: 'incorrect-plan', label: 'Incorrect Plan Data' },
  { value: 'missing-plan', label: 'Missing Plan' },
  { value: 'general', label: 'General Feedback' }
];

const WEBHOOK_URL = process.env.REACT_APP_FEEDBACK_WEBHOOK_URL;

const FeedbackForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, handleInputChange, resetForm] = useFormInput({
    type: 'general',
    message: ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!WEBHOOK_URL) {
      console.error('Feedback webhook URL not configured');
      setStatus({ 
        type: 'error', 
        message: 'Feedback system not configured properly. Please try again later.' 
      });
      return;
    }

    if (!formData.message.trim()) {
      setStatus({ type: 'error', message: 'Please enter a message' });
      return;
    }

    setIsSubmitting(true);
    setStatus({ type: '', message: '' });

    try {
      const response = await fetch(WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: FEEDBACK_TYPES.find(t => t.value === formData.type)?.label,
          message: formData.message,
          timestamp: new Date().toISOString()
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send feedback');
      }

      setStatus({
        type: 'success',
        message: 'Thank you for your feedback! We will review it shortly.'
      });

      // Reset form after successful submission
      resetForm();

      // Close modal after 3 seconds
      setTimeout(() => {
        setIsOpen(false);
        setStatus({ type: '', message: '' });
      }, 3000);

    } catch (error) {
      console.error('Error submitting feedback:', error);
      setStatus({
        type: 'error',
        message: 'An error occurred while submitting your feedback. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <button
        className="feedbackButton"
        onClick={() => setIsOpen(true)}
        aria-label="Open feedback form"
      >
        Feedback
      </button>

      {isOpen && (
        <div className="modalOverlay" onClick={(e) => {
          if (e.target === e.currentTarget) setIsOpen(false);
        }}>
          <div 
            className="modalContent"
            role="dialog"
            aria-labelledby="feedbackFormTitle"
          >
            <button
              className="closeButton"
              onClick={() => setIsOpen(false)}
              aria-label="Close feedback form"
            >
              ×
            </button>

            <h2 id="feedbackFormTitle">Send Feedback</h2>

            <form onSubmit={handleSubmit} className="form">
              <div className="formGroup">
                <label htmlFor="type" className="label">Feedback Type</label>
                <select
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleInputChange}
                  className="select"
                >
                  {FEEDBACK_TYPES.map(type => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="formGroup">
                <label htmlFor="message" className="label">Message *</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="textarea"
                  required
                  aria-required="true"
                  placeholder="Please provide details..."
                />
              </div>

              <button
                type="submit"
                className="submitButton"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send Feedback'}
              </button>

              {status.message && (
                <div
                  className={status.type === 'error' ? 'errorMessage' : 'successMessage'}
                  role="alert"
                >
                  {status.message}
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackForm;