import { useState, useCallback } from 'react';

/**
 * Custom hook for handling form input state and changes with validation
 * @param {Object} initialState - Initial form state
 * @param {Object} validation - Optional validation rules
 * @param {Object} options - Optional configuration
 * @returns {[Object, Function, Function, Function]} - [formState, handleChange, resetForm, setField]
 */
const useFormInput = (initialState = {}, validation = {}, options = {}) => {
  const [formState, setFormState] = useState(initialState);

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === 'checkbox' ? checked : value;

    // Handle boolean values from select elements
    if (type === 'select-one' && (value === 'true' || value === 'false')) {
      newValue = value === 'true';
    }

    // Apply validation rules if they exist for this field
    if (validation[name]) {
      const rule = validation[name];
      
      // Number range validation
      if (rule.type === 'number') {
        const numValue = parseInt(value, 10);
        if (isNaN(numValue)) return;
        if (rule.min !== undefined && numValue < rule.min) {
          newValue = rule.min.toString();
        }
        if (rule.max !== undefined && numValue > rule.max) {
          newValue = rule.max.toString();
        }
      }
      
      // Text length validation
      if (rule.type === 'text' && rule.maxLength !== undefined) {
        if (value.length > rule.maxLength) {
          newValue = value.slice(0, rule.maxLength);
        }
      }

      // Custom validation function
      if (rule.validate && !rule.validate(newValue)) {
        return;
      }
    }

    setFormState(prev => ({
      ...prev,
      [name]: newValue
    }));
  }, [validation]);

  // Direct field setter for complex objects or special cases
  const setField = useCallback((name, value) => {
    setFormState(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const resetForm = useCallback((newState = initialState) => {
    setFormState(newState);
  }, [initialState]);

  return [formState, handleChange, resetForm, setField];
};

export default useFormInput;