import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { supabase } from "./supabaseClient";
import { isAdmin } from "./utils/adminCheck";
import AuthProvider, { useAuth } from "./components/AuthProvider";
import UsernameSetup from "./components/UsernameSetup";
import PlanList from "./components/PlanList";
import ApparelList from "./components/ApparelList";
import Calculator from "./components/Calculator";
import PublicTradeProfile from "./components/PublicTradeProfile";
import ThemeSwitcher from "./components/ThemeSwitcher";
import AdminPanel from "./components/AdminPanel";
import Storage from "./components/Storage";
import ErrorBoundary from "./components/ErrorBoundary";
import FeedbackForm from "./components/FeedbackForm";
import AuthButtons from "./components/AuthButtons";
import ChangePasswordForm from "./components/ChangePasswordForm";
import "./App.css";
import "./themes.css";
import storage from "./utils/storage";

function AppContent() {
  const { session, loading } = useAuth();
  const [hasUsername, setHasUsername] = useState(true);
  const [theme, setTheme] = useState(() => storage.theme.get());
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (session) {
      checkUsername(session.user.id);
    }
  }, [session]);

  useEffect(() => {
    document.body.setAttribute("data-theme", theme);
    storage.theme.set(theme);
  }, [theme]);

  const checkUsername = async (userId) => {
    const { data, error } = await supabase
      .from('user_profiles')
      .select('username')
      .eq('id', userId)
      .single();

    if (error || !data) {
      setHasUsername(false);
    } else {
      setHasUsername(true);
    }
  };

  const handleUsernameSetup = () => {
    setHasUsername(true);
  };

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
  };

  const handleCategoryChange = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="wrapper">
      <div className="overlay"></div>
      <button
        className={`sidebar-toggle ${isSidebarOpen ? "open" : ""}`}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        ☰
      </button>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="sidebar-header">
          <ErrorBoundary>
            <AuthButtons />
          </ErrorBoundary>
        </div>
        <nav>
          <ul>
            <li>
              <Link to="plans" onClick={() => handleCategoryChange("plans")}>Plans</Link>
            </li>
            <li>
              <Link to="apparel" onClick={() => handleCategoryChange("apparel")}>Apparel</Link>
            </li>
            <li>
              <Link to="calculator" onClick={() => handleCategoryChange("calculator")}>Calculator</Link>
            </li>
            <li>
              <Link to="storage" onClick={() => handleCategoryChange("storage")}>Storage</Link>
            </li>
            {session && isAdmin(session.user.id) && (
              <li>
                <Link to="admin" onClick={() => handleCategoryChange("admin")}>Admin</Link>
              </li>
            )}
          </ul>
        </nav>
        <div className="sidebar-footer">
          <div>
            Special thanks to{" "}
            <a href="https://fed76.info/plan-collectors">The Plan Collectors</a>{" "}
            <a href="https://discord.gg/ksF63Z2">(Discord)</a>
          </div>
          <div className="theme-selector-sidebar">
            <ErrorBoundary>
              <ThemeSwitcher currentTheme={theme} setTheme={handleThemeChange} />
            </ErrorBoundary>
          </div>
        </div>
      </div>
      <div className="main">
        <header className="header-style">
          <div className="header-content">
            <div className="title-container">
              <h1>B.R.A.H.M.I.N</h1>
              <h4>Blueprint Registry Archive for Hoarders of Miscellaneous In-game Necessities</h4>
            </div>
          </div>
        </header>
        <ErrorBoundary>
          {!session && (location.pathname === "storage" || location.pathname.startsWith("storage/")) ? (
            <div className="sign-in-message">Please sign in to access storage.</div>
          ) : !session || hasUsername ? (
            <Routes>
              <Route path="" element={
                <ErrorBoundary>
                  <PlanList session={session} sessionInitialized={!loading} />
                </ErrorBoundary>
              } />
              <Route path="plans" element={
                <ErrorBoundary>
                  <PlanList session={session} sessionInitialized={!loading} />
                </ErrorBoundary>
              } />
              <Route path="apparel" element={
                <ErrorBoundary>
                  <ApparelList session={session} />
                </ErrorBoundary>
              } />
              <Route path="calculator" element={
                <ErrorBoundary>
                  <Calculator />
                </ErrorBoundary>
              } />
              <Route path="trader/:userId" element={
                <ErrorBoundary>
                  <PublicTradeProfile />
                </ErrorBoundary>
              } />
              <Route path="admin" element={
                <ErrorBoundary>
                  <AdminPanel session={session} />
                </ErrorBoundary>
              } />
              <Route path="storage" element={
                <ErrorBoundary>
                  <Storage session={session} />
                </ErrorBoundary>
              } />
              <Route path="change-password" element={
                <ErrorBoundary>
                  <ChangePasswordForm />
                </ErrorBoundary>
              } />
              <Route path=":characterId" element={
                <ErrorBoundary>
                  <Storage session={session} />
                </ErrorBoundary>
              } />
            </Routes>
          ) : (
            <ErrorBoundary>
              <UsernameSetup session={session} onComplete={handleUsernameSetup} />
            </ErrorBoundary>
          )}
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <FeedbackForm />
      </ErrorBoundary>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <ErrorBoundary>
          <AppContent />
        </ErrorBoundary>
      </Router>
    </AuthProvider>
  );
}

export default App;