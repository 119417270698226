import { useState, useMemo } from 'react';

/**
 * Custom hook for handling search and filter functionality
 * @param {Object} options Configuration options
 * @param {Array} options.items Array of items to filter
 * @param {Function} options.filterPredicate Custom filter predicate function
 * @param {Object} options.initialFilters Initial filter states
 * @param {Function} options.searchPredicate Custom search predicate function
 * @returns {Object} Search and filter state and handlers
 */
export const useSearchAndFilter = ({
  items,
  filterPredicate,
  initialFilters = {},
  searchPredicate
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState(initialFilters);

  // Handle search input changes
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle filter changes
  const handleFilterChange = (filterKey, value) => {
    setFilters(prev => ({
      ...prev,
      [filterKey]: typeof value === 'function' ? value(prev[filterKey]) : value
    }));
  };

  // Memoized filtered items
  const filteredItems = useMemo(() => {
    if (!searchTerm && !Object.values(filters).some(Boolean)) {
      return items;
    }

    return items.filter(item => {
      // Apply custom filter predicate if provided
      if (filterPredicate && !filterPredicate(item, filters)) {
        return false;
      }

      // Apply custom search predicate if provided
      if (searchTerm && searchPredicate) {
        return searchPredicate(item, searchTerm);
      }

      // Default search behavior if no custom predicate provided
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        return Object.values(item).some(value =>
          String(value).toLowerCase().includes(searchLower)
        );
      }

      return true;
    });
  }, [items, searchTerm, filters, filterPredicate, searchPredicate]);

  return {
    // State
    searchTerm,
    filters,
    filteredItems,

    // Handlers
    handleSearch,
    handleFilterChange,
    
    // Setters
    setSearchTerm,
    setFilters
  };
};

export default useSearchAndFilter;