import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import './Auth.css';

const ChangePasswordForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const { loading, updatePassword, session } = useAuth();
  const navigate = useNavigate();

  // Redirect if not authenticated
  useEffect(() => {
    if (!session) {
      navigate('/');
    }
  }, [session, navigate]);

  // Password validation
  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });

  const validatePassword = (value) => {
    setValidations({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(value)
    });
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const isPasswordValid = () => {
    return Object.values(validations).every(Boolean) && 
           password === confirmPassword;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isPasswordValid()) {
      setMessage('Please ensure all password requirements are met');
      setIsSuccess(false);
      return;
    }

    const result = await updatePassword(password);
    setIsSuccess(result.success);
    setMessage(result.message);

    if (result.success) {
      // Clear form and redirect after successful update
      setPassword('');
      setConfirmPassword('');
      setTimeout(() => navigate('/'), 2000);
    }
  };

  if (!session) {
    return null; // Don't render anything while redirecting
  }

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h2>Change Your Password</h2>
        <p>Please enter your new password below.</p>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="new-password">New Password</label>
            <input
              id="new-password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="••••••••"
              required
              disabled={loading || isSuccess}
            />
            <div className="password-requirements">
              <p>Password must contain:</p>
              <ul>
                <li className={validations.length ? 'valid' : ''}>
                  At least 8 characters
                </li>
                <li className={validations.uppercase ? 'valid' : ''}>
                  One uppercase letter
                </li>
                <li className={validations.lowercase ? 'valid' : ''}>
                  One lowercase letter
                </li>
                <li className={validations.number ? 'valid' : ''}>
                  One number
                </li>
                <li className={validations.special ? 'valid' : ''}>
                  One special character
                </li>
              </ul>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              id="confirm-password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="••••••••"
              required
              disabled={loading || isSuccess}
            />
            {confirmPassword && password !== confirmPassword && (
              <div className="validation-message">
                Passwords do not match
              </div>
            )}
          </div>

          {message && (
            <div 
              className={`auth-message ${isSuccess ? 'success' : 'error'}`}
              role="alert"
            >
              {message}
            </div>
          )}

          <button
            type="submit"
            className="custom-button auth-button"
            disabled={loading || isSuccess || !isPasswordValid()}
          >
            {loading ? 'Updating...' : 'Update Password'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordForm;