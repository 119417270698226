import React, { useState, useEffect } from 'react';
import Auth from './Auth';
import { useAuth } from './AuthProvider';
import { supabase } from '../supabaseClient';
import './AuthButtons.css';

const AuthButtons = () => {
  const { session, signOut, loading } = useAuth();
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const fetchUsername = async () => {
      if (session?.user) {
        try {
          const { data, error } = await supabase
            .from('user_profiles')
            .select('username')
            .eq('id', session.user.id)
            .maybeSingle();

          if (error) throw error;
          if (data) setUsername(data.username);
        } catch (error) {
          console.error('Error fetching username:', error);
        }
      }
    };

    fetchUsername();
  }, [session]);

  return (
    <div className="auth-buttons">
      {session ? (
        <div className="signout-container">
          {username && <p className="welcome-message">Welcome {username}!</p>}
          <button 
            onClick={signOut} 
            className="custom-button"
            disabled={loading}
          >
            {loading ? 'Signing Out...' : 'Sign Out'}
          </button>
        </div>
      ) : (
        <Auth />
      )}
    </div>
  );
};

export default AuthButtons;