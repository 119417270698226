import React, { useCallback, useMemo, memo } from 'react';

const themes = ['default', 'blue', 'amber', 'purple', 'cyan', 'yellow']; // Add more theme names as needed

const ThemeSwitcher = memo(({ currentTheme, setTheme }) => {
  const handleThemeChange = useCallback((e) => {
    setTheme(e.target.value);
  }, [setTheme]);

  const themeOptions = useMemo(() => (
    themes.map(theme => (
      <option key={theme} value={theme}>
        {theme.charAt(0).toUpperCase() + theme.slice(1)}
      </option>
    ))
  ), []); // themes is constant, so no dependencies needed

  return (
    <div className="theme-selector">
      <label htmlFor="theme-select">Theme: </label>
      <select
        id="theme-select"
        value={currentTheme}
        onChange={handleThemeChange}
        className="theme-menu"
      >
        {themeOptions}
      </select>
    </div>
  );
});

ThemeSwitcher.displayName = 'ThemeSwitcher';

export default ThemeSwitcher;