import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import useFormInput from '../utils/hooks/useFormInput';

const USERNAME_VALIDATION = {
  username: {
    type: 'text',
    maxLength: 30,
    validate: (value) => /^[a-zA-Z0-9_-]+$/.test(value) // Only allow alphanumeric, underscore, and hyphen
  }
};

const UsernameSetup = ({ session, onComplete }) => {
  const [loading, setLoading] = useState(true);
  const [existingProfile, setExistingProfile] = useState(null);
  const [formData, handleChange] = useFormInput(
    { username: '' },
    USERNAME_VALIDATION
  );

  useEffect(() => {
    const checkExistingProfile = async () => {
      try {
        const { data, error } = await supabase
          .from('user_profiles')
          .select('username')
          .eq('id', session.user.id)
          .maybeSingle();

        if (error) throw error;
        setExistingProfile(data);
      } catch (error) {
        console.error('Error checking profile:', error);
      } finally {
        setLoading(false);
      }
    };

    checkExistingProfile();
  }, [session.user.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.username.trim()) {
      alert('Please enter a username');
      return;
    }

    try {
      const username = formData.username.trim();
      
      // Validate username format
      if (!/^[a-zA-Z0-9_-]+$/.test(username)) {
        alert('Username can only contain letters, numbers, underscores, and hyphens');
        return;
      }

      // If profile exists and has a username, don't allow changes
      if (existingProfile?.username) {
        alert('Username has already been set and cannot be changed.');
        return;
      }

      // Check if username is taken
      const { data: existingUsername, error: checkError } = await supabase
        .from('user_profiles')
        .select('id')
        .eq('username', username)
        .maybeSingle();

      if (checkError) throw checkError;

      if (existingUsername) {
        alert('This username is already taken. Please choose another one.');
        return;
      }

      let error;
      if (existingProfile) {
        // Profile exists but no username, set it
        const { error: updateError } = await supabase
          .from('user_profiles')
          .update({ username })
          .eq('id', session.user.id);
        error = updateError;
      } else {
        // Create new profile with username
        const { error: insertError } = await supabase
          .from('user_profiles')
          .insert([{ id: session.user.id, username }]);
        error = insertError;
      }

      if (error) {
        console.error('Error setting username:', error);
        if (error.code === '23505') { // Unique violation
          alert('This username is already taken. Please choose another one.');
        } else {
          alert('Error setting username. Please try again.');
        }
        return;
      }
      
      onComplete();
    } catch (error) {
      console.error('Error setting username:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (existingProfile?.username) {
    return (
      <div className="username-setup">
        <h2>Username Already Set</h2>
        <p>Your username has already been set and cannot be changed.</p>
      </div>
    );
  }

  return (
    <div className="username-setup">
      <h2>Set Your Username</h2>
      <p>Choose your username carefully as it cannot be changed later.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          placeholder="Enter your desired username"
          pattern="[a-zA-Z0-9_-]+"
          title="Username can only contain letters, numbers, underscores, and hyphens"
          required
        />
        <button type="submit">Set Username</button>
      </form>
    </div>
  );
};

export default UsernameSetup;