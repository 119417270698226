import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import weaponData from '../weaponData.json';
import useFormInput from '../utils/hooks/useFormInput';
import Tooltip from './Tooltip';
import './Storage.css';

const INITIAL_WEAPON_STATE = {
  weapon_name: '',
  prefix_1: '',
  prefix_2: '',
  prefix_3: '',
  quantity: '1',
  comments: ''
};

const WEAPON_VALIDATION = {
  quantity: {
    type: 'number',
    min: 1,
    max: 99
  },
  comments: {
    type: 'text',
    maxLength: 100
  }
};

const Storage = ({ session }) => {
  const { characterId } = useParams();
  const navigate = useNavigate();
  const [weapons, setWeapons] = useState([]);
  const [characters, setCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [editingWeapon, setEditingWeapon] = useState(null);
  const [newWeapon, handleWeaponChange, resetWeaponForm] = useFormInput(INITIAL_WEAPON_STATE, WEAPON_VALIDATION);
  const [newCharacter, handleCharacterChange, resetCharacterForm] = useFormInput({ name: '' }, {
    name: {
      type: 'text',
      maxLength: 25
    }
  });
  const [isAddingCharacter, setIsAddingCharacter] = useState(false);

  const fetchCharacters = useCallback(async () => {
    if (session && session.user) {
      const { data, error } = await supabase
        .from('user_characters')
        .select('*')
        .eq('user_id', session.user.id);
      if (error) console.error('Error fetching characters:', error);
      else setCharacters(data);
    }
  }, [session]);

  const fetchWeapons = useCallback(async (charId) => {
    if (session && session.user && charId) {
      const { data, error } = await supabase
        .from('weapon_storage')
        .select('*')
        .eq('user_id', session.user.id)
        .eq('character_id', charId);
      if (error) console.error('Error fetching weapons:', error);
      else setWeapons(data);
    }
  }, [session]);

  useEffect(() => {
    fetchCharacters();
  }, [fetchCharacters]);

  useEffect(() => {
    if (characterId && characters.length > 0) {
      const character = characters.find(char => char.id === characterId);
      if (character) {
        setSelectedCharacter(character);
        fetchWeapons(character.id);
      } else {
        setSelectedCharacter(null);
        navigate('/');
      }
    } else if (!characterId) {
      setSelectedCharacter(null);
    }
  }, [characterId, characters, fetchWeapons, navigate]);

  const handleBackToSelection = () => {
    setSelectedCharacter(null);
    navigate('/');
  };

  const handleCharacterSelect = (character) => {
    navigate(`/${character.id}`);
  };

  const handleAddCharacter = async () => {
    if (!newCharacter.name.trim() || characters.length >= 5) return;

    const { data, error } = await supabase
      .from('user_characters')
      .insert({ user_id: session.user.id, character_name: newCharacter.name.trim() })
      .select();

    if (error) console.error('Error adding character:', error);
    else {
      await fetchCharacters();
      resetCharacterForm();
      setIsAddingCharacter(false);
      if (data && data[0]) {
        navigate(`/${data[0].id}`);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!session || !session.user || !selectedCharacter) {
      console.error('User is not authenticated or no character selected');
      return;
    }
  
    const weaponData = {
      user_id: session.user.id,
      character_id: selectedCharacter.id,
      ...newWeapon
    };
  
    if (editingWeapon) {
      const { error } = await supabase
        .from('weapon_storage')
        .update(weaponData)
        .eq('id', editingWeapon.id);
  
      if (error) console.error('Error updating weapon:', error);
      else {
        fetchWeapons(selectedCharacter.id);
        setEditingWeapon(null);
      }
    } else {
      const { error } = await supabase
        .from('weapon_storage')
        .insert(weaponData);
  
      if (error) console.error('Error adding weapon:', error);
      else {
        fetchWeapons(selectedCharacter.id);
      }
    }
  
    resetWeaponForm();
  };

  const handleEdit = (weapon) => {
    setEditingWeapon(weapon);
    resetWeaponForm({
      ...weapon,
      quantity: weapon.quantity.toString(),
    });
  };

  const handleDelete = async (id) => {
    if (!session || !session.user) {
      console.error('User is not authenticated');
      return;
    }
    const { error } = await supabase
      .from('weapon_storage')
      .delete()
      .match({ id });
    if (error) {
      console.error('Error deleting weapon:', error);
    } else {
      fetchWeapons(selectedCharacter.id);
    }
  };

  if (!session || !session.user) {
    return <div className="storage">Please log in to access weapon storage.</div>;
  }

  return (
    <div className="storage">
      <div className="storage-content">
        {!selectedCharacter ? (
          <div className="character-selection">
            <h2>Select a Character</h2>
            <div className="character-list">
              {characters.map(char => (
                <button key={char.id} onClick={() => handleCharacterSelect(char)} className="character-btn">
                  {char.character_name}
                </button>
              ))}
              {characters.length < 5 && (
                <button onClick={() => setIsAddingCharacter(true)} className="add-character-btn">
                  Add New Character
                </button>
              )}
            </div>
            {isAddingCharacter && (
              <div className="add-character-form">
                <input
                  type="text"
                  name="name"
                  value={newCharacter.name}
                  onChange={handleCharacterChange}
                  placeholder="New character name"
                  maxLength={25}
                />
                <button onClick={handleAddCharacter}>Add</button>
                <button onClick={() => {
                  setIsAddingCharacter(false);
                  resetCharacterForm();
                }}>Cancel</button>
              </div>
            )}
          </div>
        ) : (
          <>
            <h2>{selectedCharacter.character_name}'s Weapons</h2>
            <button onClick={handleBackToSelection} className="back-btn">
              Back to Character Selection
            </button>
            <div className="weapon-form-container">
              <h3>{editingWeapon ? 'Edit Weapon' : 'Add New Weapon'}</h3>
              <form onSubmit={handleSubmit} className="weapon-form">
                <div className="form-group">
                  <label htmlFor="weapon_name">Weapon</label>
                  <select 
                    id="weapon_name" 
                    name="weapon_name" 
                    value={newWeapon.weapon_name} 
                    onChange={handleWeaponChange} 
                    required
                  >
                    <option value="">Select Weapon</option>
                    {weaponData.weaponTypes.map(type => (
                      <optgroup key={type} label={type}>
                        {weaponData.weaponNames[type].map(weapon => (
                          <option key={weapon} value={weapon}>{weapon}</option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                </div>

                <div className="form-group prefixes">
                  <label>Legendary Effects</label>
                  <div className="prefix-selects">
                    <select name="prefix_1" value={newWeapon.prefix_1} onChange={handleWeaponChange}>
                      <option value="">1★ Effect</option>
                      {weaponData.prefixes['1star'].map(prefix => (
                        <option key={prefix} value={prefix}>{prefix}</option>
                      ))}
                    </select>
                    <select name="prefix_2" value={newWeapon.prefix_2} onChange={handleWeaponChange}>
                      <option value="">2★ Effect</option>
                      {weaponData.prefixes['2star'].map(prefix => (
                        <option key={prefix} value={prefix}>{prefix}</option>
                      ))}
                    </select>
                    <select name="prefix_3" value={newWeapon.prefix_3} onChange={handleWeaponChange}>
                      <option value="">3★ Effect</option>
                      {weaponData.prefixes['3star'].map(prefix => (
                        <option key={prefix} value={prefix}>{prefix}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="quantity">Quantity</label>
                  <input 
                    type="number" 
                    id="quantity" 
                    name="quantity" 
                    value={newWeapon.quantity} 
                    onChange={handleWeaponChange} 
                    min="1" 
                    max="99"
                    required 
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="comments">Comments</label>
                  <textarea 
                    id="comments" 
                    name="comments" 
                    value={newWeapon.comments} 
                    onChange={handleWeaponChange} 
                    placeholder="Any additional notes" 
                    maxLength={100}
                  />
                  <div className="character-count">
                    {newWeapon.comments.length}/100 characters
                  </div>
                </div>

                <button type="submit" className="submit-btn">
                  {editingWeapon ? 'Update Weapon' : 'Add Weapon'}
                </button>
                {editingWeapon && (
                  <button 
                    type="button" 
                    className="cancel-btn" 
                    onClick={() => {
                      setEditingWeapon(null);
                      resetWeaponForm();
                    }}
                  >
                    Cancel Edit
                  </button>
                )}
              </form>
            </div>
            
            <div className="weapon-list-container">
              <h3>Stored Weapons</h3>
              <div className="weapon-list">
                {weapons.map(weapon => (
                  <div key={weapon.id} className="weapon-item">
                    <div className="weapon-name">{weapon.weapon_name}</div>
                    <div className="weapon-prefixes">
                      {weapon.prefix_1 && (
                        <Tooltip text={weaponData.legendaryEffects[weapon.prefix_1]}>
                          <span className="prefix">{weapon.prefix_1}</span>
                        </Tooltip>
                      )}
                      {weapon.prefix_2 && (
                        <Tooltip text={weaponData.legendaryEffects[weapon.prefix_2]}>
                          <span className="prefix">{weapon.prefix_2}</span>
                        </Tooltip>
                      )}
                      {weapon.prefix_3 && (
                        <Tooltip text={weaponData.legendaryEffects[weapon.prefix_3]}>
                          <span className="prefix">{weapon.prefix_3}</span>
                        </Tooltip>
                      )}
                    </div>
                    <div className="weapon-details">
                      Quantity: {weapon.quantity}
                    </div>
                    {weapon.comments && <div className="weapon-comments">Notes: {weapon.comments}</div>}
                    <div className="weapon-item-footer">
                      <button onClick={() => handleEdit(weapon)} className="edit-btn">Edit</button>
                      <button onClick={() => handleDelete(weapon.id)} className="delete-btn">Delete</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Storage;
