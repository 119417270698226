import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null, 
      errorInfo: null,
      retryCount: 0
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Update state with error details
    this.setState(prevState => ({
      error,
      errorInfo,
      retryCount: prevState.retryCount + 1
    }));

    // Log error to monitoring service
    this.logError(error, errorInfo);
  }

  logError = (error, errorInfo) => {
    // TODO: Replace console.error with proper error tracking service
    console.error('Error caught by boundary:', {
      error,
      errorInfo,
      timestamp: new Date().toISOString(),
      retryCount: this.state.retryCount,
      componentStack: errorInfo.componentStack
    });
  };

  handleRetry = () => {
    // Reset error state
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null
    });

    // Call optional onRetry prop if provided
    if (this.props.onRetry) {
      this.props.onRetry();
    }
  };

  handleRefresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // Determine if we should show retry button based on error type and retry count
      const canRetry = this.state.retryCount < 3 && 
        !(this.state.error instanceof TypeError) && // Don't retry type errors
        !(this.state.error instanceof ReferenceError); // Don't retry reference errors

      return (
        <div className="error-boundary">
          <h1>Something went wrong</h1>
          <p>We apologize for the inconvenience.</p>
          
          <div className="error-actions">
            {canRetry && (
              <button 
                onClick={this.handleRetry}
                className="custom-button retry-button"
              >
                Try Again
              </button>
            )}
            <button 
              onClick={this.handleRefresh}
              className="custom-button refresh-button"
            >
              Refresh Page
            </button>
          </div>

          {/* Show error details in development */}
          {process.env.NODE_ENV === 'development' && (
            <details className="error-details">
              <summary>Error Details</summary>
              <pre>
                {this.state.error && (
                  <>
                    {this.state.error.toString()}
                    <br />
                    {this.state.errorInfo && this.state.errorInfo.componentStack}
                  </>
                )}
              </pre>
            </details>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;