import React, { useState } from 'react';
import { useAuth } from './AuthProvider';
import './Auth.css';

const ForgotPasswordForm = ({ onCancel }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const { loading, resetPassword } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const result = await resetPassword(email);
    setIsSuccess(result.success);
    setMessage(result.message);
    
    if (result.success) {
      setEmail(''); // Clear form on success
    }
  };

  return (
    <div className="auth-form">
      <h2>Reset Password</h2>
      <p>Enter your email address to receive password reset instructions.</p>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="reset-email">Email</label>
          <input
            id="reset-email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="your@email.com"
            required
            disabled={loading || isSuccess}
          />
        </div>

        {message && (
          <div 
            className={`auth-message ${isSuccess ? 'success' : 'error'}`}
            role="alert"
          >
            {message}
          </div>
        )}

        <div className="auth-buttons">
          <button
            type="submit"
            className="custom-button auth-button"
            disabled={loading || isSuccess}
          >
            {loading ? 'Sending...' : 'Send Reset Instructions'}
          </button>
          
          <button
            type="button"
            className="custom-button secondary"
            onClick={onCancel}
            disabled={loading}
          >
            Back to Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;