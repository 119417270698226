import React, { useState, useEffect, useCallback, useMemo } from "react";
import { supabase, getTable, getCompletedPlansTable } from "../supabaseClient";
import { sanitizeText, sanitizeSearchQuery, sanitizeLocation } from "../utils/security";
import CollapsibleItem from "./CollapsibleItem";
import storage from "../utils/storage";
import useSearchAndFilter from "../utils/hooks/useSearchAndFilter";

const ErrorMessage = ({ message, onRetry }) => (
  <div className="error-message">
    <p>{message}</p>
    {onRetry && (
      <button onClick={onRetry} className="custom-button">
        Retry
      </button>
    )}
  </div>
);

const PAGE_SIZE = 1000;

const usePlansData = (session) => {
  const [plans, setPlans] = useState([]);
  const [completedPlans, setCompletedPlans] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setLoadingProgress(0);
    setError(null);

    try {
      // Calculate total steps upfront
      let totalSteps = 0;
      let completedSteps = 0;

      // Check cache first
      const cachedPlans = storage.plans.getCache();
      if (cachedPlans) {
        totalSteps = 2; // Just need to load cache and completed plans
      } else {
        // Get plan count first to calculate pages needed
        const { count } = await supabase
          .from(getTable())
          .select("*", { count: "exact", head: true });

        const planPages = Math.ceil(count / PAGE_SIZE);
        totalSteps = planPages + 1; // Add 1 for completed plans step
      }

      // If logged in, get completed plans count to add those pages
      if (session) {
        const { count: completedCount } = await supabase
          .from(getCompletedPlansTable())
          .select("*", { count: "exact", head: true })
          .eq("user_id", session.user.id);

        const completedPages = Math.ceil(completedCount / PAGE_SIZE);
        totalSteps += completedPages;
      } else {
        totalSteps += 1; // Just one step for local storage
      }

      const updateProgress = () => {
        completedSteps++;
        setLoadingProgress(Math.round((completedSteps / totalSteps) * 100));
      };

      // Now start actual data fetching
      if (cachedPlans) {
        setPlans(cachedPlans);
        updateProgress();
      } else {
        let allPlans = [];
        let page = 0;
        let hasMore = true;

        while (hasMore) {
          const { data, error, count } = await supabase
            .from(getTable())
            .select("*", { count: "exact" })
            .range(page * PAGE_SIZE, (page + 1) * PAGE_SIZE - 1);

          if (error) {
            throw error;
          }

          allPlans = [...allPlans, ...data];
          hasMore = count > (page + 1) * PAGE_SIZE;
          page++;
          completedSteps++;
          setLoadingProgress(Math.round((completedSteps / totalSteps) * 100));
        }

        setPlans(allPlans);
        storage.plans.setCache(allPlans);
        updateProgress();
      }

      // Fetch Completed Plans
      if (session) {
        let allCompletedPlans = [];
        let page = 0;
        let hasMore = true;

        while (hasMore) {
          const { data, error, count } = await supabase
            .from(getCompletedPlansTable())
            .select("plan_id", { count: "exact" })
            .eq("user_id", session.user.id)
            .range(page * PAGE_SIZE, (page + 1) * PAGE_SIZE - 1);

          if (error) {
            console.error("Error fetching completed plans:", error);
            break;
          }

          allCompletedPlans = [...allCompletedPlans, ...data];
          hasMore = count > (page + 1) * PAGE_SIZE;
          page++;
          completedSteps++;
          setLoadingProgress(Math.round((completedSteps / totalSteps) * 100));
        }

        const completedPlanIds = allCompletedPlans.reduce((acc, item) => {
          acc[item.plan_id] = true;
          return acc;
        }, {});
        setCompletedPlans(completedPlanIds);
        updateProgress();
      } else {
        const savedCompletedPlans = storage.plans.getCompleted();
        if (savedCompletedPlans) {
          setCompletedPlans(savedCompletedPlans);
        }
        updateProgress();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message || "Failed to load plans. Please try again.");
      setPlans([]);
    } finally {
      setIsLoading(false);
    }
  }, [session]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    plans,
    completedPlans,
    setCompletedPlans,
    isLoading,
    loadingProgress,
    error,
    retryFetch: fetchData
  };
};

const usePlanManagement = (session, completedPlans, setCompletedPlans) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");
  const [error, setError] = useState(null);

  const handleCheckboxChange = useCallback(
    async (planId, forceValue = null) => {
      try {
        const newValue = forceValue !== null ? forceValue : !completedPlans[planId];
        const newCompletedPlans = {
          ...completedPlans,
          [planId]: newValue,
        };
        setCompletedPlans(newCompletedPlans);

        if (session) {
          const { error } = await supabase
            .from(getCompletedPlansTable())
            .upsert(
              { user_id: session.user.id, plan_id: planId },
              { onConflict: ["user_id", "plan_id"] }
            )
            .select()
            .single();
          
          if (error) throw error;
        } else {
          try {
            storage.plans.setCompleted(newCompletedPlans);
          } catch (storageError) {
            console.error("Error saving to localStorage:", storageError);
            throw new Error("Failed to save progress locally");
          }
        }
      } catch (error) {
        console.error("Error updating plan status:", error);
        // Revert the change
        setCompletedPlans(completedPlans);
        setError("Failed to update plan status. Please try again.");
        setTimeout(() => setError(null), 3000);
      }
    },
    [session, completedPlans, setCompletedPlans]
  );

  const handleCheckAll = useCallback(
    async (items, checked) => {
      try {
        const newCompletedPlans = { ...completedPlans };
        const promises = items.map(async (item) => {
          newCompletedPlans[item.planId] = checked;
          if (session) {
            if (checked) {
              // If checking, use upsert
              const { error } = await supabase
                .from(getCompletedPlansTable())
                .upsert(
                  { user_id: session.user.id, plan_id: item.planId },
                  { onConflict: ["user_id", "plan_id"] }
                )
                .select()
                .single();
              
              if (error) throw error;
            } else {
              // If unchecking, delete the entry
              const { error } = await supabase
                .from(getCompletedPlansTable())
                .delete()
                .eq('user_id', session.user.id)
                .eq('plan_id', item.planId);
              
              if (error) throw error;
            }
          }
        });

        await Promise.all(promises);
        setCompletedPlans(newCompletedPlans);

        if (!session) {
          try {
            storage.plans.setCompleted(newCompletedPlans);
          } catch (storageError) {
            console.error("Error saving to localStorage:", storageError);
            throw new Error("Failed to save progress locally");
          }
        }
      } catch (error) {
        console.error("Error updating multiple plans:", error);
        setError("Failed to update all plans. Please try again.");
        setTimeout(() => setError(null), 3000);
      }
    },
    [session, completedPlans, setCompletedPlans]
  );

  const handleDownload = useCallback(() => {
    try {
      const completedPlanIds = Object.keys(completedPlans).filter(
        (id) => completedPlans[id]
      );
      const dataStr = JSON.stringify(completedPlanIds);
      const dataUri = "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);
      const exportFileDefaultName = "completed_plans.json";

      const linkElement = document.createElement("a");
      linkElement.setAttribute("href", dataUri);
      linkElement.setAttribute("download", exportFileDefaultName);
      linkElement.click();
    } catch (error) {
      console.error("Error downloading progress:", error);
      setError("Failed to download progress. Please try again.");
      setTimeout(() => setError(null), 3000);
    }
  }, [completedPlans]);

  const handleUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      if (file.size > 1024 * 1024) { // 1MB limit
        setError("File size too large. Please upload a smaller file.");
        setTimeout(() => setError(null), 3000);
        return;
      }

      setIsUploading(true);
      setUploadProgress(0);
      setUploadStatus("Uploading...");
      setError(null);

      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          let uploadedPlanIds;
          try {
            uploadedPlanIds = JSON.parse(e.target.result);
            if (!Array.isArray(uploadedPlanIds)) {
              throw new Error("Invalid file format. Expected an array of plan IDs.");
            }
          } catch (parseError) {
            throw new Error("Invalid JSON format. Please upload a valid progress file.");
          }

          const newCompletedPlans = { ...completedPlans };

          if (session) {
            const batchSize = 1000;
            const totalPlans = uploadedPlanIds.length;

            for (let i = 0; i < uploadedPlanIds.length; i += batchSize) {
              const batch = uploadedPlanIds.slice(
                i,
                Math.min(i + batchSize, uploadedPlanIds.length)
              );
              const { error } = await supabase
                .from(getCompletedPlansTable())
                .upsert(
                  batch.map((planId) => ({
                    user_id: session.user.id,
                    plan_id: planId,
                  })),
                  {
                    onConflict: ["user_id", "plan_id"],
                    ignoreDuplicates: true,
                  }
                );

              if (error) throw error;

              batch.forEach((planId) => {
                newCompletedPlans[planId] = true;
              });

              const progress = Math.round(((i + batch.length) / totalPlans) * 100);
              setUploadProgress(progress);
            }
          } else {
            uploadedPlanIds.forEach((id) => {
              newCompletedPlans[id] = true;
            });
            try {
              storage.plans.setCompleted(newCompletedPlans);
            } catch (storageError) {
              throw new Error("Failed to save progress locally");
            }
            setUploadProgress(100);
          }

          setCompletedPlans(newCompletedPlans);
          setUploadStatus("Upload complete. All plans have been added.");
        } catch (error) {
          console.error("Error during upload:", error);
          setError(error.message || "An error occurred during upload. Please try again.");
        } finally {
          setTimeout(() => {
            setIsUploading(false);
            setUploadProgress(0);
            setUploadStatus("");
            setError(null);
          }, 3000);
        }
      };

      reader.onerror = () => {
        setError("Failed to read file. Please try again.");
        setIsUploading(false);
        setTimeout(() => setError(null), 3000);
      };

      reader.readAsText(file);
    },
    [session, completedPlans, setCompletedPlans]
  );

  return {
    handleCheckboxChange,
    handleCheckAll,
    handleDownload,
    handleUpload,
    isUploading,
    uploadProgress,
    uploadStatus,
    error
  };
};

const usePlanOrganization = (plans, completedPlans) => {
  const organizedPlans = useMemo(() => {
    const organized = { obtainable: {}, unobtainable: {} };
    let totalObtainable = 0;
    let totalUnobtainable = 0;

    plans.forEach((plan) => {
      const {
        item_type,
        item_name,
        section,
        name,
        location,
        plan_id,
        obtainable,
      } = plan;

      const category =
        obtainable === true || obtainable === "true"
          ? "obtainable"
          : "unobtainable";
      if (obtainable === true) {
        totalObtainable++;
      } else {
        totalUnobtainable++;
      }

      if (!organized[category][item_type])
        organized[category][item_type] = {};

      if (!item_name || item_name.trim() === "") {
        // If item_name is null, undefined, or an empty string, nest section directly under item_type
        if (!organized[category][item_type][section])
          organized[category][item_type][section] = [];
        organized[category][item_type][section].push({
          name,
          location,
          planId: plan_id,
        });
      } else {
        if (!organized[category][item_type][item_name])
          organized[category][item_type][item_name] = {};
        if (!organized[category][item_type][item_name][section])
          organized[category][item_type][item_name][section] = [];
        organized[category][item_type][item_name][section].push({
          name,
          location,
          planId: plan_id,
        });
      }
    });

    // Sort at all levels: item_type, item_name/section, and name
    ["obtainable", "unobtainable"].forEach((category) => {
      organized[category] = Object.entries(organized[category])
        .sort(([a], [b]) => a.localeCompare(b))
        .reduce((sorted, [itemType, content]) => {
          sorted[itemType] = Object.entries(content)
            .sort(([a], [b]) => a.localeCompare(b))
            .reduce((sortedContent, [key, value]) => {
              if (Array.isArray(value)) {
                // This is a section directly under item_type (when item_name was null or empty)
                sortedContent[key] = value.sort((a, b) =>
                  a.name.localeCompare(b.name)
                );
              } else {
                // This is an item_name
                sortedContent[key] = Object.entries(value)
                  .sort(([a], [b]) => a.localeCompare(b))
                  .reduce((sortedSections, [section, items]) => {
                    sortedSections[section] = items.sort((a, b) =>
                      a.name.localeCompare(b.name)
                    );
                    return sortedSections;
                  }, {});
              }
              return sortedContent;
            }, {});
          return sorted;
        }, {});
    });

    return {
      organizedPlans: organized,
      totalObtainablePlans: totalObtainable,
      totalUnobtainablePlans: totalUnobtainable,
    };
  }, [plans]);

  const { completedObtainablePlans, completedUnobtainablePlans } = useMemo(() => {
    const obtainable = plans.filter(
      (plan) =>
        (plan.obtainable === true || plan.obtainable === "true") &&
        completedPlans[plan.plan_id]
    ).length;

    const unobtainable = plans.filter(
      (plan) =>
        (plan.obtainable === false || plan.obtainable === "false") &&
        completedPlans[plan.plan_id]
    ).length;

    return {
      completedObtainablePlans: obtainable,
      completedUnobtainablePlans: unobtainable,
    };
  }, [plans, completedPlans]);

  return {
    ...organizedPlans,
    completedObtainablePlans,
    completedUnobtainablePlans
  };
};

const PlanList = ({ session }) => {
  const {
    plans,
    completedPlans,
    setCompletedPlans,
    isLoading,
    loadingProgress,
    error: fetchError,
    retryFetch
  } = usePlansData(session);

  const {
    handleCheckboxChange,
    handleCheckAll,
    handleDownload,
    handleUpload,
    isUploading,
    uploadProgress,
    uploadStatus,
    error: managementError
  } = usePlanManagement(session, completedPlans, setCompletedPlans);

  const {
    organizedPlans,
    totalObtainablePlans,
    totalUnobtainablePlans,
    completedObtainablePlans,
    completedUnobtainablePlans
  } = usePlanOrganization(plans, completedPlans);

  const {
    searchTerm,
    filters: {
      hideCompleted,
      showOnlyCompleted
    },
    handleSearch,
    handleFilterChange
  } = useSearchAndFilter({
    items: plans,
    initialFilters: {
      hideCompleted: false,
      showOnlyCompleted: false
    },
    filterPredicate: (item, filters) => {
      const { hideCompleted, showOnlyCompleted } = filters;
      return (!hideCompleted || !completedPlans[item.plan_id]) &&
        (!showOnlyCompleted || completedPlans[item.plan_id]);
    },
    searchPredicate: (item, searchTerm) => {
      const searchLower = sanitizeSearchQuery(searchTerm).toLowerCase();
      return (item.name && item.name.toLowerCase().includes(searchLower)) ||
        (item.location && item.location.toLowerCase().includes(searchLower));
    }
  });

  // Handle filter changes
  const handleHideCompletedChange = (event) => {
    const newValue = event.target.checked;
    handleFilterChange('hideCompleted', newValue);
    if (newValue) {
      handleFilterChange('showOnlyCompleted', false);
    }
  };

  const handleShowOnlyCompletedChange = (event) => {
    const newValue = event.target.checked;
    handleFilterChange('showOnlyCompleted', newValue);
    if (newValue) {
      handleFilterChange('hideCompleted', false);
    }
  };

  // Apply filters to organized plans
  const filteredPlans = useMemo(() => {
    if (!searchTerm && !hideCompleted && !showOnlyCompleted) {
      return organizedPlans;
    }

    const filtered = { obtainable: {}, unobtainable: {} };
    ["obtainable", "unobtainable"].forEach((category) => {
      Object.entries(organizedPlans[category]).forEach(([itemType, content]) => {
        const filteredContent = {};
        Object.entries(content).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            // This is a section directly under item_type
            const filteredItems = value.filter(item =>
              (!hideCompleted || !completedPlans[item.planId]) &&
              (!showOnlyCompleted || completedPlans[item.planId]) &&
              ((item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.location && item.location.toLowerCase().includes(searchTerm.toLowerCase())))
            );
            if (filteredItems.length > 0) {
              filteredContent[key] = filteredItems;
            }
          } else {
            // This is an item_name
            const filteredSections = {};
            Object.entries(value).forEach(([section, items]) => {
              const filteredItems = items.filter(item =>
                (!hideCompleted || !completedPlans[item.planId]) &&
                (!showOnlyCompleted || completedPlans[item.planId]) &&
                ((item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                  (item.location && item.location.toLowerCase().includes(searchTerm.toLowerCase())))
              );
              if (filteredItems.length > 0) {
                filteredSections[section] = filteredItems;
              }
            });
            if (Object.keys(filteredSections).length > 0) {
              filteredContent[key] = filteredSections;
            }
          }
        });
        if (Object.keys(filteredContent).length > 0) {
          filtered[category][itemType] = filteredContent;
        }
      });
    });
    return filtered;
  }, [organizedPlans, searchTerm, hideCompleted, showOnlyCompleted, completedPlans]);

  if (fetchError) {
    return <ErrorMessage message={fetchError} onRetry={retryFetch} />;
  }

  return (
    <div className="plan-list">
      <div className={`content-wrapper ${isLoading || isUploading ? "blurred" : ""}`}>
        {managementError && (
          <div className="error-banner">
            {managementError}
          </div>
        )}
        <div className="infobox">
          <div className="info-container">
            <div className="plans-section">
              <span className="plans-label">Obtainable Progress: </span>
              <span className="plans-numbers">
                {completedObtainablePlans} / {totalObtainablePlans}
              </span>
            </div>
            <div className="plans-section">
              <span className="plans-label">Unobtainable Progress: </span>
              <span className="plans-numbers">
                {completedUnobtainablePlans} / {totalUnobtainablePlans}
              </span>
            </div>
            <div className="button-container">
              <button
                onClick={handleDownload}
                className="custom-button"
                disabled={isLoading || isUploading}
              >
                Download Progress
              </button>
              <label className="custom-file-upload">
                <input
                  type="file"
                  onChange={handleUpload}
                  accept=".json"
                  disabled={isLoading || isUploading}
                />
                Upload Progress
              </label>
              {(isUploading || uploadStatus) && (
                <span className="loading-indicator">
                  {uploadStatus || "Uploading... Please don't refresh the page."}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search plans..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
            maxLength={100}
          />
        </div>
        <div className="hide-completed-container">
          <label className="hide-completed-label">
            <input
              type="checkbox"
              checked={hideCompleted}
              onChange={handleHideCompletedChange}
              className="hide-completed-checkbox"
            />
            Hide Completed
          </label>
          <label className="hide-completed-label">
            <input
              type="checkbox"
              checked={showOnlyCompleted}
              onChange={handleShowOnlyCompletedChange}
              className="hide-completed-checkbox"
            />
            Show Only Completed
          </label>
        </div>
        {["obtainable", "unobtainable"].map((category) => (
          <div key={category}>
            <h2
              className={
                category === "unobtainable" ? "unobtainable-plans-title" : "Obtainable-plans"
              }
            >
              {category === "obtainable" ? "Obtainable" : "Unobtainable"} Plans
            </h2>
            {Object.entries(filteredPlans[category]).map(
              ([itemType, content]) => (
                <CollapsibleItem
                  key={itemType}
                  title={itemType}
                  isUnobtainable={category === "unobtainable"}
                >
                  {Object.entries(content).map(([key, value]) => {
                    if (Array.isArray(value)) {
                      // This is a section directly under item_type (when item_name was null)
                      return (
                        <CollapsibleItem
                          key={key}
                          title={key}
                          isUnobtainable={category === "unobtainable"}
                          isCheckAll={value.every(
                            (item) => completedPlans[item.planId]
                          )}
                          onCheckAllChange={() => {
                            const allChecked = value.every(
                              (item) => completedPlans[item.planId]
                            );
                            handleCheckAll(value, !allChecked);
                          }}
                        >
                          {value.map((item) => (
                            <div
                              key={item.planId}
                              className="plan-container-style"
                            >
                              <label className="plan-label">
                                <input
                                  type="checkbox"
                                  className="plan-checkbox-style"
                                  checked={completedPlans[item.planId] || false}
                                  onChange={() =>
                                    handleCheckboxChange(item.planId)
                                  }
                                />
                                <span
                                  className={`plan-name-style ${
                                    category === "unobtainable"
                                      ? "unobtainable-plan"
                                      : ""
                                  }`}
                                >
                                  {sanitizeText(item.name)}
                                </span>
                              </label>
                              <div className="location-text-style">
                                {sanitizeLocation(item.location)}
                              </div>
                            </div>
                          ))}
                        </CollapsibleItem>
                      );
                    } else {
                      // This is an item_name
                      return (
                        <CollapsibleItem
                          key={key}
                          title={key}
                          isUnobtainable={category === "unobtainable"}
                        >
                          {Object.entries(value).map(([section, items]) => (
                            <CollapsibleItem
                              key={section}
                              title={section}
                              isCheckAll={items.every(
                                (item) => completedPlans[item.planId]
                              )}
                              onCheckAllChange={() => {
                                const allChecked = items.every(
                                  (item) => completedPlans[item.planId]
                                );
                                handleCheckAll(items, !allChecked);
                              }}
                              isUnobtainable={category === "unobtainable"}
                            >
                              {items.map((item) => (
                                <div
                                  key={item.planId}
                                  className="plan-container-style"
                                >
                                  <label className="plan-label">
                                    <input
                                      type="checkbox"
                                      className="plan-checkbox-style"
                                      checked={
                                        completedPlans[item.planId] || false
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(item.planId)
                                      }
                                    />
                                    <span
                                      className={`plan-name-style ${
                                        category === "unobtainable"
                                          ? "unobtainable-plan"
                                          : ""
                                      }`}
                                    >
                                      {sanitizeText(item.name)}
                                    </span>
                                  </label>
                                  <div className="location-text-style">
                                    {sanitizeLocation(item.location)}
                                  </div>
                                </div>
                              ))}
                            </CollapsibleItem>
                          ))}
                        </CollapsibleItem>
                      );
                    }
                  })}
                </CollapsibleItem>
              )
            )}
          </div>
        ))}
      </div>
      {isLoading && (
        <div className="upload-overlay">
          <div className="upload-message">
            <h3>Loading Plans</h3>
            <p>
              Please wait while we fetch the plans. This may take a few
              moments.
            </p>
            <progress value={loadingProgress} max="100"></progress>
            <p>{loadingProgress}% Complete</p>
          </div>
        </div>
      )}
      {isUploading && (
        <div className="upload-overlay">
          <div className="upload-message">
            <h3>Uploading Progress</h3>
            <p>
              {uploadStatus ||
                "Please don't refresh the page. This may take a few moments."}
            </p>
            <progress value={uploadProgress} max="100"></progress>
            <p>{uploadProgress}% Complete</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanList;