import DOMPurify from 'dompurify';

/**
 * Sanitizes HTML content to prevent XSS attacks
 * @param {string} content - The content to sanitize
 * @returns {string} - Sanitized content safe for rendering
 */
export const sanitizeHtml = (content) => {
  if (!content) return '';
  return DOMPurify.sanitize(content, {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'span'],
    ALLOWED_ATTR: ['class']
  });
};

/**
 * Sanitizes text content by removing HTML and special characters
 * @param {string} text - The text to sanitize
 * @returns {string} - Sanitized text
 */
export const sanitizeText = (text) => {
  if (!text) return '';
  return text
    .replace(/<[^>]*>/g, '') // Remove HTML tags
    .replace(/[<>{}()[\]\\^$|#]/g, '') // Remove special characters
    .replace(/[^\w\s-]/g, '') // Only allow word characters, spaces, and hyphens
    .trim()
    .slice(0, 1000); // Limit length to prevent DoS
};

/**
 * Sanitizes a search query
 * @param {string} query - The search query to sanitize
 * @returns {string} - Sanitized search query
 */
export const sanitizeSearchQuery = (query) => {
  if (!query) return '';
  return query
    .replace(/[<>{}()[\]\\^$|#]/g, '') // Remove special characters
    .replace(/[^\w\s-]/g, '') // Only allow word characters, spaces, and hyphens
    .trim()
    .slice(0, 100); // Limit length for search queries
};

/**
 * Sanitizes location text while preserving some formatting
 * @param {string} location - The location text to sanitize
 * @returns {string} - Sanitized location text
 */
export const sanitizeLocation = (location) => {
  if (!location) return '';
  return DOMPurify.sanitize(location, {
    ALLOWED_TAGS: [], // No HTML tags allowed
    ALLOWED_ATTR: [] // No attributes allowed
  })
    .replace(/[<>]/g, '') // Remove angle brackets
    .trim()
    .slice(0, 500); // Reasonable limit for location descriptions
};